.help-center-details {
  // max-width: 1200px;
  margin: 20px 60px;
  font-family: Arial, sans-serif;

  /* Back Button */
  .back-button {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: #03696e;
    padding: 10px 0;
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-bottom: 25px;

    // &:hover {
    //     text-decoration: underline;
    // }
  }

  .helpcenter-container {
    display: flex;
    gap: 20px;

    .sidebar {
      width: 250px;
      height: 545px; // Fixed height based on viewport height
      // max-height: 500px;  // Ensures it doesn't take the full screen on large screens
      // overflow-y: auto;  // Enables scrolling if content exceeds max height
      background: #f8f8f8;
      padding: 15px;
      border-radius: 5px;
      border: 1px solid #ddd;
      position: sticky;
      top: 20px; // Keeps sidebar fixed while scrolling

      h3 {
        font-size: 16px;
        margin-bottom: 10px;
      }

      .help-box {
        display: flex;
        align-items: center;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: #eaeaea;
        }

        .icon {
          font-size: 20px;
          margin-right: 10px;
        }

        .title {
          font-weight: bold;
          font-size: 14px;
        }

        .desc {
          font-size: 12px;
          color: #666;
        }
      }
    }

    .content {
      flex: 1;
      background: white;
      padding: 20px;
      // border-radius: 5px;
      // border: 1px solid #ddd;
      overflow: hidden;

      h2 {
        font-size: 20px;
        margin-bottom: 10px;
      }

      p {
        font-size: 15px;
        color: #333;
        line-height: 1.6;
        font-weight: 400;

        a {
          color: #0073e6;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      ol {
        margin-left: 20px;
        font-size: 14px;

        li {
          margin-bottom: 5px;
        }
      }

      .note {
        background: #fff8e1;
        padding: 10px;
        border-left: 4px solid #ffcc00;
        margin-top: 10px;
        font-size: 14px;

        strong {
          font-weight: bold;
        }

        ul {
          margin: 5px 0 0 15px;
        }
      }

      .feedback-button {
        background: #f0f0f0;
        border: 1px solid #ccc;
        padding: 8px 12px;
        font-size: 14px;
        cursor: pointer;
        margin-top: 10px;

        &:hover {
          background: #e0e0e0;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .container {
      flex-direction: column;
    }

    .sidebar {
      height: auto;
      max-height: none;
      position: relative;
    }

    .content {
      padding: 15px;
    }
  }
}

@media (max-width: 768px) {
  .help-center-details {
    margin: 20px 20px;
    .helpcenter-container {
      flex-direction: column;
      .sidebar {
        width: 100%;
        position: static;
        height: 420px;
      }
    }
  }
}

@media (max-width: 425px) {
  .help-center-details {
    .helpcenter-container {
      .sidebar {
        width: 100%;
        position: static;
        height: 440px !important;
      }
    }
  }
}

@media (max-width: 407px) {
    .help-center-details {
      .helpcenter-container {
        .sidebar {
          width: 100%;
          position: static;
          height: 520px !important;
        }
      }
    }
  }
