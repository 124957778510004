$black: #000000;
$gray: #E1DFDF;
$white: #ffffff;
$bg-gray: #C6CBDB;
$thm_blue: #00B4D7;
$light_gray: #B3B0B0;
$dark_gray: #474646;
$dark_black: #201010;
$light_black: #A39F9F;

.tracking-details-main-box {
    padding: 24px 19px;

    .tracking-heder {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        margin-bottom: 26px;

        .title {
            h4 {
                font-size: 18px;
                font-weight: 700;
                line-height: 27px;
                color: $dark_gray;
                margin-bottom: 2px;
            }

            p {
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                color: $black;
                margin-bottom: 0px;
            }
        }

        .close-btn {
            button {
                margin-bottom: 0px;
                background-color: transparent;
                border: none;
                cursor: pointer;
            }
        }
    }

    .tracking-body {
        display: flex;
        gap: 21px;

        .tracking-details {
            flex: 1;

            .tracking-item-summary {
                background-color: $white;
                padding: 18px 20px;

                .main-title {
                    text-align: center;

                    h2 {
                        font-size: 18px;
                        line-height: 27px;
                        font-weight: 700;
                        color: $dark_black;
                        margin-bottom: 2px;
                    }

                    h4 {
                        font-size: 12px;
                        line-height: 18px;
                        font-weight: 400;
                        color: $dark_black;
                        margin-bottom: 0px;
                    }
                }

                .title {
                    margin-bottom: 18px;

                    h5 {
                        font-size: 14px;
                        line-height: 21px;
                        font-weight: 700;
                        color: $dark_gray;
                        margin-bottom: 0px;
                    }

                    p {
                        font-size: 14px;
                        line-height: 21px;
                        font-weight: 400;
                        color: $dark_gray;
                        margin-bottom: 0px;
                    }
                }

                .details {
                    display: flex;
                    gap: 8px;
                    min-width: 170px;

                    p {
                        max-width: 335px;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 21px;
                        color: $dark_black;
                        margin-bottom: 8px;
                    }

                    h4 {
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 24px;
                        color: $dark_black;
                        margin-bottom: 0px;
                    }

                    img {
                        height: 100%;
                    }
                }

                .view-more {
                    text-align: end;

                    button {
                        background-color: transparent;
                        border: none;
                        color: $thm_blue;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 21px;
                        cursor: pointer;
                    }
                }

                .MuiPaper-root {
                    box-shadow: none;
                    margin-bottom: 12px;
                    border: 1px solid #B2B6B7;
                    border-radius: 4px;

                    table {
                        thead {
                            background-color: #f3f3f5;
                            color: #3f3e3e;
                            tr {
                                th { 
                                    // border: 1px solid #DAD7D7;
                                    padding: 8px;
                            
                                    // &:first-child {
                                    //   border-left: none;
                                    // }
                            
                                    // &:last-child {
                                    //   border-right: none;
                                    // }
                                }
                            }
                        }
                        tbody {
                            tr {
                                td {
                                    // border: 1px solid #DAD7D7;
                                    padding: 8px;

                                    // &:first-child {
                                    //     border-left: none;
                                    // }

                                    &:last-child {
                                        border-bottom: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .tracking-summary-details {
            width: 100%;
            max-width: 310px;

            .tracking-order-invoice {
                background-color: $white;
                padding: 16px 14px;
                margin-bottom: 10px;

                h5 {
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 21px;
                    color: $dark_gray;
                    margin-bottom: 16px;
                }

                div {
                    display: flex;
                    justify-content: space-between;
                    gap: 8px;

                    p {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 21px;
                        color: $black;
                        margin-bottom: 5px;
                    }

                    h6 {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 21px;
                        color: $black;
                        margin-bottom: 5px;
                        text-align: end;

                        &.free {
                            color: $thm_blue;
                        }
                    }

                    &.order-invoice {
                        p {
                            font-weight: 500;
                        }

                        h6 {
                            font-weight: 500;
                            color: $dark_black;
                            text-align: end;
                        }
                    }


                    &.payment-method {
                        h6 {
                            font-weight: 500;
                            color: $dark_black;
                        }
                    }
                }

                hr {
                    background-color: #E3E0E0;
                    margin: 11px 0px;
                }
            }

            .tracking-delivery-address {
                padding: 16px 14px 24px;
                background-color: $white;
                margin-bottom: 0px;

                h5 {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 21px;
                    color: $dark_black;
                }

                p {
                    font-size: 11px;
                    font-weight: 400;
                    line-height: 17px;
                    color: $light_black;
                }

                h6 {
                    font-size: 11px;
                    font-weight: 400;
                    line-height: 17px;
                    color: $light_black;

                    .verified {
                        color: $light_black;
                        font-weight: 400;
                    }
                }
            }

            .tracking-invoice-summary {
                padding: 16px 14px 16px;
                background-color: $white;
                display: flex;
                gap: 10px;
                justify-content: space-between;
                align-items: flex-start;
                margin-bottom: 9px;

                h5 {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 21px;
                    color: $dark_black;
                }

                p {
                    font-size: 11px;
                    font-weight: 400;
                    line-height: 17px;
                    color: $light_black;
                }

                button {
                    background-color: transparent;
                    border: none;
                    cursor: pointer;
                }
            }
        }
    }
}

@media (max-width:1200px) {
    .tracking-details-main-box {
        padding: 0px 0px;

        .tracking-body {
            gap: 12px;

            .tracking-summary-details {
                max-width: 250px;
            }
        }
    }
}

@media (max-width:1024px) {
    .tracking-details-main-box {
        .tracking-body {
            flex-direction: column;

            .tracking-summary-details {
                max-width: 100%;
            }
        }
    }
}

@media (max-width: 991px) {
    .tracking-details-main-box {
        .tracking-body {
            .tracking-summary-details {
                display: block;
            }
        }
    }
}

@media (max-width:767px) {
    .tracking-details-main-box {
        .tracking-body {
            .tracking-details {
                .tracking-item-summary {
                    padding: 12px 8px;
                }
            }
        }
    }
}

@media (max-width:575px) {
    .tracking-details-main-box {
        .tracking-body {
            .tracking-details {
                .tracking-item-summary {
                    .details {
                        p {
                            font-size: 12px;
                            line-height: 16px;
                        }

                        h4 {
                            font-size: 14px;
                            line-height: 18px;
                        }
                    }
                }
            }
        }
    }
}