$black: #000000;
$gray: #e1dfdf;
$white: #ffffff;
$bg-gray: #c6cbdb;
$thm_blue: #00b4d7;
$light_gray: #b3b0b0;
$dark_gray: #474646;
$dark_black: #201010;

// .dash-content-wrap {
//     background: $bg-gray;
// }

.order-list-header {
  display: flex;
  gap: 30px;
  margin-bottom: 40px;

  .order-name {
    margin-inline-end: auto;

    h4 {
      font-size: 18px;
      font-weight: 700;
      line-height: 27px;
      color: $dark_gray;
      margin-bottom: 2px;
    }

    p {
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      color: $black;
      margin-bottom: 0px;
      margin-top: 4px;
    }
  }

  .order-search {
    max-width: 350px;
    width: 100%;
    // margin-top: 25px;

    .MuiFormControl-root {
      margin: 0px;
      width: 100%;
      background: $white;

      .MuiInputBase-input {
        font-size: 16px;
      }

      .MuiOutlinedInput-notchedOutline {
        border: none;
        border-radius: 1px;
      }
    }
  }

  .order-dropdown {
    max-width: 167px;
    width: 100%;
    // margin-top: 25px;

    .MuiFormControl-root {
      margin: 0px;
      width: 100%;
      background: $white;

      .MuiInputBase-input {
        font-size: 14px;
      }

      .MuiOutlinedInput-notchedOutline {
        border: none;
        border-radius: 1px;
      }
    }
  }
}

.MuiMenu-list {
  .Mui-selected {
    background-color: rgba(16, 205, 242) !important;
    color: $white;
  }

  .MuiMenuItem-root {
    padding: 10px 23px;
    font-size: 14px;
  }
}

.order-list-items {
  .title {
    h4 {
      font-size: 18px;
      font-weight: 700;
      line-height: 27px;
      color: $black;
      margin-bottom: 18px;
    }
  }

  .card-items {
    background-color: $white;
    border: 1px solid $gray;
    padding: 24px 24px 3px 24px;
    margin-bottom: 10px;

    &.modified-class{
      padding: 24px 24px 12px 24px;
    }

    .card-delivery-time-number {
      display: flex;
      justify-content: space-between;
      gap: 1px;
      margin-bottom: 6px;

      &.modified-class{
        flex-wrap: wrap;
      }

      .product-img-name {
        display: flex;
        gap:10px;

        .product-image {
          img {
            max-width: 130px;
            min-width: 100px;
            width: 100px;
            height: auto;
          }
        }

        .product-name {
          display: flex;
          justify-content: center;
          align-items: center;

          h5 {
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            color: $dark_black;
            min-width: 250px;
            max-width: 201px;
            margin-bottom: 0px;
          }
        }
      }

      .card-delivery-time {
        display: flex;
        flex-direction: column;
        gap: 2px;
        margin-bottom: 0px;

        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          color: $black;
          // margin-bottom: -18px;

          span {
            color: $thm_blue;
          }
        }
      }

      .product-order-id {
        display: flex;
        flex-direction: column;
        gap: 15px;
        .cancle-btn{
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 100%;
        }
        
        p {
          font-size: 14px;
          font-weight: bold;
          line-height: 18px;
          margin-bottom: 0px;
          color: $dark_black;
          text-align: end;
          white-space: nowrap;
        }

        .cancle-btn {
          button {
            background: $thm_blue;
            color: $black;
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            border: none;
            padding: 6px 22px;
            cursor: pointer;
          }
        }
      }

      .product-return-item {
        display: flex;
        flex-direction: column;
        // width:30%;
        width:35%;
        justify-content: center;

        &.modified-class {
          display: flex;
          flex-direction: column; 
          gap: 0px; 
          justify-content: center;
        }

        .return-item {
          display: flex;
          flex-direction: column;
          gap:2px;
          margin-top: -6px;

          .return-btn{
            button {
              background: $thm_blue;
              color: $black;
              font-size: 14px;
              line-height: 21px;
              font-weight: 500;
              border: none;
              padding: 6px 22px;
              cursor: pointer;
            }
          }
          
          p {
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            margin-bottom: 0px;
            color: $dark_black;
            // text-align: end;
            // padding:5px;
            padding:5px 0px;

            span{
              color:#00B4D7;
              font-weight: 500;
            }
          }
        }

        .end-content {
          gap: 25px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          // height: 100%;

          &.modified-class {
            display: flex;
            flex-direction: row; 
            align-items: center; 
            gap: 10px; 
            justify-content: space-between;
            // margin: -12px 2px;
          }
          .product-btn-grp {
            // margin-top: -17px;
            p {
              font-size: 14px;
              font-weight: 400;
              line-height: 21px;
              color: $dark_black;
              margin-bottom: 11px;
            }

            div {
              display: flex;
              gap: 6px;

              button {
                background: $thm_blue;
                color: $black;
                font-size: 14px;
                line-height: 21px;
                font-weight: 400;
                border: none;
                padding: 6px 22px;
                cursor: pointer;
              }
            }
          }

          .product-more-details {
            display: flex;
            flex-direction: column;
            align-items: end;
            width:100%;

            &.modified-class {
              width:unset;
            }

            button {
              background-color: transparent;
              border: none;
              cursor: pointer;
              margin-bottom: 22px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1201px) {
  .order-list-items {
    .card-items {
      .card-delivery-time-number {
        .product-img-name {
          flex: 1;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .order-list-header {
    gap: 12px;
    margin-bottom: 40px;

    .order-search {
      max-width: 270px;
    }

    .order-dropdown {
      max-width: 137px;
    }
  }
}

@media (max-width: 1254px) {
    .order-list-items {
        .card-items {
          &.modified-class{
            padding-bottom: 24px;
          }

          .card-delivery-time-number{
            .product-return-item{
              width: 100%;
              display: flex;
              justify-content: center;

              &.modified-class {
                display: unset;
              }
            
            // .end-content{
            //     // justify-content: space-between;
            // }
            }
            .product-order-id{
                gap:12px;
            }
          } 

        }
    }
}

@media (min-width: 768px) {
  .order-list-items {
    .card-items {
      .card-delivery-time-number {
        .product-img-name {
          width: 100%;
          max-width: 100%;
          // margin-top:17px;
        }
        // .product-more-details {
        //     margin-top: 50px;
        // }
      }
    }
  }
}

@media (max-width: 767px) {
  .order-list-header {
    flex-wrap: wrap;
    margin-bottom: 30px;

    .order-name {
      order: 0;
      max-width: 246px;
      margin-top: -20px;
    }

    .order-search {
      order: 2;
      max-width: 100%;
      margin-top: 0px;
    }

    .order-dropdown {
      order: 1;
      max-width: 150px;
      margin-top: 0px;
    }
  }

  .order-list-items {
    .card-items {
      padding: 10px;

      .card-delivery-time-number {
        flex-wrap:wrap;
        .card-delivery-time {
          margin-bottom: -10px;
          gap: unset;
          p {
            font-size: 12px;
          }
        }

        .product-img-name {
          .product-name {
            h5 {
              max-width: 201px;
              margin-bottom: 0px;
            }
          }
        }

        .product-order-id {
          p {
            font-size: 13px;
          }
        }
      }
      .product-details {
        gap: 10px;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 18px;

        .product-more-details {
          width: 100%;
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;

          button {
            margin-bottom: 0px;
          }
        }
      }
    }
  }

  .MuiMenu-list .MuiMenuItem-root {
    padding: 6px 16px;
  }
}

@media (max-width: 575px) {
  .order-list-items {
    .card-items {
      padding: 10px;

      .card-delivery-time-number {
        gap:8px;
        .product-img-name {
          .product-image {
            img {
              max-width: 90px;
              // margin: 22px 4px;
            }
          }

          .product-name {
            h5 {
              font-size: 12px;
              min-width: 0px;
            }
          }
        }

        .product-return-item {
            gap:2px;

            .end-content {
                .product-btn-grp {
                  p {
                    font-size: 12px;
                  }
  
                  div {
                  flex-wrap: wrap !important;
                    button {
                      font-size: 14px;
                      padding: 6px 12px;
                    }
                  }
                }
                .product-more-details {
                  button {
                    svg {
                      max-width: 14px;
                    }
                  }
                }
              }

              .return-item{
                p {
                  font-size: 12px;
                }
              }
        }

        .product-order-id {
            gap: 10px !important;
          }
      }
    }
  }

  .MuiMenu-list .MuiMenuItem-root {
    padding: 6px 16px;
  }
}

.deskPagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  padding-top: 15px;
  padding-left: 10px;
  padding-right: 10px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: stretch;
  }

  .pagination-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    @media (max-width: 600px) {
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .items-label {
      font-size: 13px;

      @media (max-width: 600px) {
        font-size: 12px;
      }
    }

    .items-select {
      font-size: 13px;
      width: 100px;

      @media (max-width: 600px) {
        font-size: 12px;
        width: auto;
      }

      .MuiMenuItem-root {
        font-size: 13px;

        @media (max-width: 600px) {
          font-size: 12px;
        }
      }
    }
  }

  .MuiPagination-root {
    margin-top: 10px;
    @media (max-width: 600px) {
      display: flex;
      justify-content: center;
    }

    .MuiPagination-ul {
      justify-content: flex-end;

      @media (max-width: 600px) {
        justify-content: center;
      }
    }
  }
}
@media (max-width:1023px){
  // .order-list-items .card-items .card-delivery-time-number{
  //   flex-wrap: wrap;
  // }
  .order-list-items .card-items .card-delivery-time-number .product-return-item{
    flex-direction: row;
    justify-content: flex-end;
  }
  .order-list-items .card-items .card-delivery-time-number .product-return-item .end-content .product-more-details button{
    margin: 0px;
  }
}