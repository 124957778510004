.card-box {
    .card-title {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        margin-bottom: 21px;

        .title {
            font-weight: 700;
            margin-bottom: 8px;
            font-size: 18px;
            color: #474646;
            font-family: Poppins;
        }

        .subtitle {
            font-weight: 400;
            margin-bottom: 16px;
            font-size: 13px;
            color: #000000;
            font-family: Poppins;
        }

        button {
            // margin-top: 30px;
            background-color: #00B4D7;
            color: #fff;
            font-size: 14px;
            line-height: 21px;
            font-weight: 500;
            padding: 14px 22px;
            border: none;
            max-height: 50px;
            width: max-content;
            cursor: pointer;
        }
    }

    .general-card-box {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 10px 20px;
        background-color: #fff;

        .card-details {
            display: flex;
            align-items: center;
            padding: 5px;

            .radio-btn {
                margin-right: 15px;
            }

            .payment-card {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                padding: 5px 20px;
                background-color: #fff;
                border: 1px solid #E1DFDF;
                border-radius: 2px;

                &.selected {
                    border: 1px solid #007bff;
                }

                .card-type {
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 21px;
                    text-align: left;
                    margin:4px;

                    .card-type-actual {
                        font-family: Poppins;
                        font-size: 14px;
                        font-style: italic;
                        font-weight: 600;
                        line-height: 21px;
                        text-align: left;

                    }

                }

                .card-expiry {
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 21px;
                    text-align: left;
                    color: #777;
                    margin:4px;
                }
            }

            .action-buttons {
                display: flex;
                gap: 10px;

                .edit-button {
                    color: #007bff;
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 21px;
                    text-align: left;
                    text-decoration-line: underline;
                    text-decoration-style: solid;
                    text-transform: none;

                    &:hover {
                        background-color: rgba(0, 123, 255, 0.1);
                    }
                }

                .delete-button {
                    color: #ff4d4f;
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 21px;
                    text-align: left;
                    text-decoration-line: underline;
                    text-decoration-style: solid;
                    text-transform: none;

                    &:hover {
                        background-color: rgba(255, 77, 79, 0.1);
                    }
                }
            }
        }
    }
}

@media (max-width:575px) {
    .card-box {

        .card-title {
            display: flex;
            flex-direction: column;

            .title {
                font-size: 14px;
                line-height: 15px;
            }

            .subtitle {
                font-size: 11px;
                line-height: 15px;
            }

            button {
                margin-top: -20px;
                background-color: #00B4D7;
                color: #fff;
                font-size: 12px;
                line-height: 15px;
                font-weight: 500;
                padding: 8px 15px;
                border: none;
                max-height: 30px;
                width: max-content;
                cursor: pointer;
                word-break: break-word;
                white-space: normal;
            }
        }

        .general-card-box {
            display: flex;
            flex-direction: column;
            gap: 5px;
            padding: 10px;
            background-color: #fff;

            .card-details {
                display: flex;
                align-items: center;
                padding: 5px;

                .radio-btn {
                    margin-right: 5px;
                }

                .payment-card {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 3px 10px;

                    &.selected {
                        border: 1px solid #007bff;
                    }

                    .card-type {
                        font-size: 11px;
                        line-height: 15px;

                        .card-type-actual {
                            font-size: 11px;
                            line-height: 15px;

                        }

                    }

                    .card-expiry {
                        font-size: 11px;
                        line-height: 15px;
                        color: #777;
                    }
                }

                .action-buttons {
                    display: flex;
                    gap: 11px;

                    .edit-button {
                        font-size: 11px;
                        line-height: 15px;

                    }

                    .delete-button {
                        font-size: 11px;
                        line-height: 15px;

                    }
                }
            }
        }
    }
}