.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    // margin-top: 20px;
    position: relative;
    left: -20px;
    cursor: pointer;
  }
}
@media (max-width: 768px) {
  .modal-header {
    .MuiDialogTitle-root {
      font-size: 16px;
      max-width: 90%;
      word-break: break-word;
      overflow-wrap: break-word;
    }

    svg {
      left: -10px;
    }
  }
}
