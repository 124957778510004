$black: #000000;
$gray: #E1DFDF;
$white: #ffffff;
$bg-gray: #C6CBDB;
$thm_blue: #00B4D7;
$light_gray: #B3B0B0;
$dark_gray: #474646;
$dark_black: #201010;
$thm_red: #EF1111;

.profile-box {
    padding: 0px 0px;

    .profile-title {
        margin-bottom: 21px;

        h4 {
            font-size: 18px;
            line-height: 27px;
            font-weight: 700;
            color: $dark_gray;
            margin-bottom: 2px;
        }

        p {
            font-size: 12px;
            line-height: 18px;
            font-weight: 400;
            color: $black;
            margin-bottom: 0px;
        }
    }

    .general-info-box {
        padding:21px 0px;
        background-color: $white;
        margin-bottom: 18px;

        h4 {
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            color: #202020;
            margin-bottom: 10px;
            padding: 0px 18px;
        }

        hr {
            border-color: #EFEBEB;
            margin-bottom: 16px;
        }

        .input-grp {
            padding: 0px 18px;
            display: flex;
            gap: 25px;
            margin-bottom: 22px;

            .input-box {
                flex: 1;

                p {
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 500;
                    margin-bottom: 5px;
                    // color: #202020;
                }

                .MuiInputBase-root {
                    max-height: 48px;
                    width: 100%;

                    input {
                        padding: 15px;
                    }

                    // .MuiSelect-select {
                    //     padding: 12px 26px;
                    // }
                }
            }
        }

        .update-btn {
            padding: 0px 18px;

            button {
                max-height: 48px;
                padding: 14px 26px;
                font-size: 14px;
                line-height: 21px;
                font-weight: 700;
                border: none;
                cursor: pointer;

                &.dissable {
                    background-color: #E9ECEC;
                    color: $white;
                }

                &.active {
                    background-color: $thm_blue;
                    color: $white;
                }
            }
        }
    }

    .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
        padding: 4px !important;
    }

    .address-box {
        padding:21px 0px;
        background-color: $white;
        margin-bottom: 18px;

        h4 {
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            color: #202020;
            margin-bottom: 10px;
            padding: 0px 18px;
        }

        hr {
            border-color: #EFEBEB;
            margin-bottom: 16px;
        }

        .address-grp {
            padding: 10px 18px;

            .no-address {
                display: flex;
                gap: 15px;
                align-items: end;

                h5 {
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 500;
                    color: #202020;
                }

                p {
                    font-size: 12px;
                    line-height: 18px;
                    font-weight: 400;
                    color: #202020;
                }

                button {
                    background-color: $thm_blue;
                    color: $white;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 21px;
                    border: none;
                    padding: 5px 24px;
                    cursor: pointer;
                    width: 100%;
                    max-width: 81px;
                }
            }
            
        }
    }

    .security-box {
        padding:21px 0px;
        background-color: $white;

        h4 {
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            color: #202020;
            margin-bottom: 10px;
            padding: 0px 18px;
        }

        hr {
            border-color: #EFEBEB;
            margin-bottom: 16px;
        }
        

        .input-grp {
            padding: 0px 18px;
            display: flex;
            gap: 25px;
            margin-bottom: 32px;

            .input-box {
                flex: 1;

                p {
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 500;
                    margin-bottom: 12px;
                    color: #202020;
                }

                .MuiInputBase-root {
                    max-height: 43.5px;
                    width: 100%;

                    input {
                        padding: 12px;
                    }

                    .MuiSelect-select {
                        padding: 12px;
                    }
                }
            }
        }

        .update-btn-grp {
            padding: 0px 18px;
            display: flex;
            gap: 22px;

            button {
                max-height: 40px;
                padding: 11px 26px;
                font-size: 12px;
                line-height: 18px;
                font-weight: 500;
                border: none;
                cursor: pointer;
                background-color: $thm_blue;
                color: $white;

                &.delete-btn {
                    background-color: transparent;
                    color: $thm_red;
                    text-decoration: underline;
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 400;
                    padding-left: 0px;
                    padding-right: 0px;
                }
            }
        }
    }
}

@media (max-width:1024px) {
    .profile-box {
        .general-info-box {
            .input-grp {
                gap: 8px;
            }
        }

        .security-box {
            .input-grp {
                gap: 8px;
            }
        }
    }
}

@media (max-width:767px) {
    .profile-box {
        .general-info-box {
            .input-grp {
                gap: 14px;
                flex-wrap: wrap;

                .input-box {
                    flex: auto;

                    p {
                        margin-bottom: 6px;
                        font-size: 12px;
                        line-height: 18px;
                    }

                    &.dropdown {
                        width: 100%;

                        .MuiSelect-select {
                            padding: 9px 12px;
                        }
                    }
                }
            }

            .update-btn {
                button {
                    width: 100%;
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }

        .address-box {
            .address-grp {
                .no-address {
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 0px;

                    button {
                        max-width: 100%;
                        padding: 10px 24px;
                    }
                }
            }
        }

        .security-box {
            .input-grp {
                gap: 18px;
                flex-wrap: wrap;
                flex-direction: column;

                .input-box {
                    flex: auto;

                    p {
                        margin-bottom: 6px;
                        font-size: 12px;
                        line-height: 18px;
                    }

                    &.dropdown {
                        width: 100%;

                        .MuiSelect-select {
                            padding: 9px 12px;
                        }
                    }
                }
            }

            .update-btn-grp {
                flex-wrap: wrap;
            }
        }
    }
}

@media (max-width:575px) {
    .profile-box {
        .general-info-box {
            h4 {
                padding: 0px 8px;
                margin-bottom: 21px;
            }

            hr {
                display: none;
            }

            .input-grp {
                padding: 0px 8px;
            }

            .update-btn {
                padding: 0px 8px;
            }
        }

        .address-box {
            h4 {
                padding: 0px 8px;
                margin-bottom: 21px;
            }

            hr {
                display: none;
            }

            .address-grp {
                padding: 0px 8px;
            }
        }

        .security-box {
            h4 {
                padding: 0px 8px;
                margin-bottom: 21px;
            }

            hr {
                display: none;
            }

            .input-grp {
                padding: 0px 8px;
            }

            .update-btn-grp {
                padding: 0px 8px;
                gap: 8px;

                button {
                    padding: 8px 12px;
                    font-size: 10px;

                    &.delete-btn {
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
            }
        }
    }
}

.profile-box {
    .address-box {
        .address-grp {
            .address-selection {
                display: flex;

                .address-list {
                    position: relative;
                    margin-right: 20px;
                    padding-right: 10px;
                    display: flex;
                    align-items: flex-start;

                    &::after {
                        content: '';
                        height: 65px;
                        background-color: #F2EEEE;
                        width: 1px;
                        position: absolute;
                        right: 0px;
                        top: 0px;
                    }

                    &:last-child {
                        margin-right: 0px;
                        padding-right: 0px;

                        &:after {
                            display: none;
                        }
                    }

                    .MuiButtonBase-root {
                        padding-top: 24px;

                        span {
                            svg {
                                &:first-child {
                                    color: #202020;
                                }

                                &:last-child {
                                    color: #C6CBDB;
                                    transform: scale(1);
                                }
                            }
                        }

                        span {
                            &.MuiTouchRipple-root {
                                display: none;
                            }
                        }

                        &.Mui-checked {

                            span {
                                svg {
                                    &:first-child {
                                        color: #202020;
                                    }

                                    &:last-child {
                                        color: #00B4D7;
                                    }
                                }
                            }
                        }

                        &:hover {
                            background-color: transparent;
                        }
                    }

                    .address-details {
                        p {
                            font-size: 14px;
                            line-height: 25px;
                            font-weight: 400;
                            margin: 0px;
                            color: #202020;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:1100px) {
    .profile-box {
        .address-box {
            .address-grp {
                .address-selection {
                    flex-direction: column;
                    gap: 20px;

                    .address-list {
                        margin-right: 0px;

                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}