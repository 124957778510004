.specification-typo {
    .spec-head {
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        color: #3A3A3A;
    }

    .MuiGrid-container {
        .MuiGrid-item {
            .MuiTypography-root {
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                color: #2A2929;
            }

        }
    }

    .view-more-txt {
        p {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            text-align: left;
            text-decoration-line: underline;
            text-decoration-style: solid;
            color: #343333;
            margin-bottom: auto;
        }
    }
}

.css-16haxnc {
    padding: 6px 24px;
    flex: 1;
}


.center-mode {
    display: flex;
    align-items: center;
    justify-content: center;
}

.cust-tabs-list-Is {

    margin-bottom: 15px;

    .MuiTabs-flexContainer {
        gap: 6px;

        button {
            border-radius: 5px;
            background: #F6F2F2;
        }
    }

    .MuiTabs-indicator {
        border-radius: 10px;
        border: 1px solid #1082C2;
        top: 0;
        height: 100%;
        background-color: transparent;
    }

    .MuiTabs-vertical {
        .MuiTabs-indicator {
            left: 0;
            width: 100%;
        }
    }

    &.right-img-tab {
        height: 100%;
        position: relative;
        z-index: 999;

        [role="tabpanel"] {
            width: 100%;
            background-color: #E7E5E5;
            border-radius: 10px;
            border: 1px solid #E7E5E5;
            background: #F8F4F4;
            margin: 0px 15px;

            .MuiBox-root {
                height: 100%;
                // overflow: hidden;
                @extend .center-mode;

                >div {
                    display: flex;
                    align-items: center;
                }
            }
        }

        .cust-tabs-view-img {
            position: sticky;
            top: 10px;

            .-magnifyimg {
                img {
                    max-width: fit-content;
                    max-height: 296px;
                }
            }
        }

    }

}

.right-side-content {
    padding:0px 10px;
    
    .spec-head {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
    }

    .color-name-block {
        margin-bottom: 5px;

        label {
            color: #5B5858;
            font-size: 14px;
        }

        span {
            color: #000;
            font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        }
    }

    h6{
        font-family: Poppins;
        font-size: 13px;
        font-weight: 700;
        line-height: 24.05px;
      
    }

    h5 {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 700;
        line-height: 24.05px;
        color: #000000;

    }

    p {
        font-family: Poppins;
        font-size: 12.47px;
        font-weight: 400;
        line-height: 18.7px;
        // margin-bottom: 5px;
    }

    .rating-box {
        gap: 4px;
        display: flex;
        align-items: center;
        margin-bottom: -5px;
        margin-top: 10px;

        .MuiChip-root {
            color: #FFF;
            font-size: 14px;
            font-weight: 500;

            .starIcon {
                margin: 5px;
                color: inherit;
            }
        }

        span.total-rating {
            color: #5B5858;
            font-size: 14px;
            font-weight: 500;
        }
    }

    .prod-description{
        margin-top: 15px;
     li {
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: #3D3C3C;
        margin-bottom: 2px;
    }
    .qty-box {
        .add-to-cart-btn {
          button {
            margin-top: 30px;
            background-color: red !important; // Ensure it overrides
            color: #000000;
            font-size: 14px;
            line-height: 21px;
            font-weight: 500;
            padding: 14px 22px;
            border: none;
            max-height: 50px;
            cursor: pointer;
          }
        }
      }
      

    .view-more-txt {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            text-align: left;
            text-decoration-line: underline;
            text-decoration-style: solid;
            color: #343333;
            cursor: pointer;
            margin-bottom: 5px;
    }
}

}
.right-tabs-Is {
    .tab-box {
        height: 70px !important;

        img {
            height: 100%;
            width: 100%;
        }
    }

}

@media (max-width: 960px) {
    .cust-tabs-list-Is {
        &.right-img-tab {
            [role="tabpanel"] {
                .MuiBox-root {
                    >div {
                        height: 350px;
                        width: 350px;
                    }
                }
            }
        }
    }
}


@media (max-width: 600px) {
    .cust-tabs-list-Is {
        &.right-img-tab {
            [role="tabpanel"] {
                .MuiBox-root {
                    >div {
                        height: 300px;
                        width: 300px;
                    }
                }
            }
        }
    }
}