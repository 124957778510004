.help-center {
  padding: 20px;
  max-width: 1100px;
  margin: auto;

  .management-options-title {
    margin-bottom: 30px;
    margin-top: 25px;
    h2 {
      font-size: 24px;
      margin-bottom: 10px;
    }

    .description {
      font-size: 15px;
    }
  }

  .management-options {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    gap: 15px;

    .help-box {
      display: flex;
      align-items: center;
      background: #fff;
      padding: 15px;
      border-radius: 10px;
      text-decoration: none;
      color: #333;
      border: 1px solid #e1dfdf;
      // transition: all 0.3s ease-in-out;

      &:hover {
        border: 1px solid #2cb7ee;
      }

      .icon {
        font-size: 24px;
        margin-right: 10px;
      }

      .title {
        font-size: 17px;
        margin-bottom: 5px;
        font-weight: 600;
      }

      .desc {
        font-size: 14px;
        color: #666;
      }
    }
  }

  .search-bar-title {
    h2 {
      font-size: 24px;
      margin-bottom: 20px;
      margin-top: 40px;
    }
  }

  .search-suggestion-text {
    text-align: center;
    font-size: 14px;
    color: #666;
    margin-bottom: 15px;
  
    strong {
      color: #1082c2;
      font-weight: 500;
    }
  }
  
  .search-bar {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  
    .search-input-container {
      position: relative;
      width: 100%;
      max-width: 500px;
  
      .search-icon {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 18px;
        color: #666;
      }
  
      input {
        width: 100%;
        padding: 10px 10px 10px 35px; // Add padding for the icon
        border: 1px solid #ddd;
        border-radius: 5px;
        font-size: 16px;
        outline: none;
        transition: border-color 0.3s ease-in-out;
  
        &:focus {
          border-color: #0073e6;
          box-shadow: 0 0 5px rgba(0, 115, 230, 0.3);
        }
      }
    }
  }
  
  .search-suggestions {
    background: #fff;
    border: 1px solid #ddd;
    max-width: 500px;
    margin: 0 auto 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  
    .suggestions-dropdown {
      max-height: 200px; // Adjust height as needed
      overflow-y: auto; // Make it scrollable
      padding: 10px;
  
      .suggestion-item {
        display: block;
        padding: 10px;
        color: #0073e6;
        text-decoration: none;
        transition: background 0.3s ease-in-out;
        cursor: pointer;
        font-size: 14px;
  
        &:hover {
          background: #f2f2f2;
        }
      }
    }
  }

  .search-suggestions {
    background: #fff;
    border: 1px solid #ddd;
    max-width: 500px;
    margin: 0 auto 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

    .suggestion-item {
      display: block;
      padding: 10px;
      color: #0073e6;
      text-decoration: none;
      transition: background 0.3s ease-in-out;

      &:hover {
        background: #f2f2f2;
      }
    }
  }

  .browse-topics {
    margin-top: 40px;
  
    h3 {
      font-size: 20px;
      margin-bottom: 20px;
      color: #333;
      text-align: center;
    }
  
    .help-container {
      display: flex;
      width: 100%;
      max-width: 1100px;
      margin: auto;
      background: #fff;
      border-radius: 4px;
      border: 1px solid #ddd;
      padding: 20px;
      gap: 20px; 

      h4{
        font-size:15px;
        margin-bottom:15px;
      }
  
      @media (max-width: 768px) {
        flex-direction: column;
        gap: 10px;
      }
    }
  
    .column {
      flex: 1;
      padding: 10px;
      border-right: 1px solid #eee;
  
      &:last-child {
        border-right: none;
      }
  
      @media (max-width: 768px) {
        border-right: none;
        border-bottom: 1px solid #eee;
        padding-bottom: 20px;
  
        &:last-child {
          border-bottom: none;
        }
      }
    }
  
    .category,
    .subtopic,
    .detail {
      display: flex;
      align-items: center;
      padding: 7px;
      margin-bottom: 5px;
      border-radius: 8px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      background: #f9f9f9;
      font-size: 14px;
  
      &:hover {
        background: #e6f2ff;
        transform: translateX(5px);
      }
  
      &.active {
        background: #cce5ff;
        font-weight: 500;
      }
    }
  
    .category {
      .category-icon {
        font-size: 20px;
        margin-right: 10px;
      }
  
      .category-name {
        font-size: 16px;
        color: #333;
      }
    }
  
    .subtopic {
      .subtopic-icon {
        font-size: 18px;
        margin-right: 10px;
      }
  
      .subtopic-name {
        font-size: 14px;
        color: #555;
      }
    }
  
    .detail {
      .detail-icon {
        font-size: 16px;
        margin-right: 10px;
      }
  
      .detail-text {
        font-size: 14px;
        color: #666;
      }
    }
  }

  .topics-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-width: 400px;
    margin: auto;

    .topic {
      padding: 15px;
      background: #f9f9f9;
      border-radius: 8px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      h4 {
        font-size: 18px;
        margin: 0;
      }

      &:hover {
        background: #e6f2ff;
      }
    }

    .subtopics {
      display: none;
      margin-top: 10px;
      padding-left: 0;

      li {
        list-style: none;
        margin-top: 5px;

        a {
          display: block;
          color: #0073e6;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .topic:hover .subtopics {
      display: block;
    }
  }

  @media (max-width: 768px) {
    .topics-list {
      max-width: 100%;
      padding: 10px;

      .topic {
        padding: 12px;

        h4 {
          font-size: 16px;
        }
      }
    }
  }

  .footer {
    margin-top: 50px;
    padding: 20px;
    background: rgb(37, 37, 37),
      // color: #fff;
      p {
      margin-bottom: 10px;
      text-align: center;
    }

    a {
      color: #1082c2;
      text-decoration: none;
    }

    .coffee-section {
      margin-top: 4px;
      text-align: center;

      .coffee-gifs {
        display: flex;
        justify-content: center;
        gap: 10px;

        .coffee-gif {
          width: 100px;
          height: auto;
          border-radius: 10px;
        }
      }
    }
  }
}

.topic-details {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  h2 {
    font-size: 22px;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    line-height: 1.5;
  }

  button {
    padding: 8px 15px;
    margin: 5px;
    border: none;
    background: #0073e6;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    transition: background 0.3s ease-in-out;

    &:hover {
      background: #005bb5;
    }
  }
}

@media (max-width: 600px) {
  .help-center {
    padding: 10px;

    .management-options {
      grid-template-columns: 1fr;
    }
  }
}
